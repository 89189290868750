// import Commissions from '@/models/commissions';

export default class Payout {
    public applicationKey: string;
    public hash: string;
    public orderId: string;
    public acquirer: string;
    public bankId: number;
    public terminalId: number;
    public currency: string;
    public description: string;
    public cardPan: string;
    public cardName: string;
    public email: string;
    public phone: string;
    public amount: number;
    public totalAmount: number;
    public commissionType: number;
    public totalCommission: number;
    public status: string;
    public autoClearing: boolean;
    public language: string;
    public callbacks: string;
    public createdAt: string;
    public updatedAt: string;
    public deletedAt: string;

    constructor(payout: any) {
        this.applicationKey = payout.application_key;
        this.hash = payout.hash;
        this.orderId = payout.order_id;
        this.acquirer = payout.acquirer;
        this.bankId = payout.acquirer_id;
        this.terminalId = payout.terminal_id;
        this.currency = payout.currency;
        this.description = payout.description;
        this.cardPan = payout.card_pan;
        this.cardName = payout.card_name;
        this.email = payout.email;
        this.phone = payout.phone;
        this.amount = payout.amount;
        this.commissionType = payout.commission_type;
        this.totalAmount = payout.total_amount;
        this.totalCommission = payout.total_commission;
        this.status = payout.status;
        this.autoClearing = payout.auto_clearing;
        this.language = payout.language;
        this.callbacks = payout.callbacks;
        this.createdAt = payout.created_at;
        this.updatedAt = payout.updated_at;
        this.deletedAt = payout.deleted_at;
    }
}
